import React, { useState } from 'react'
import './faq.scss'

export default function Question({title, response}) {
    const [id, setId] = useState(null)

    const showQuestion = (index) => {
        setId(!id)
    }

    return (
        <div className="question">
            <div className="q-title" onClick={() => showQuestion()}>
                <p>{title}</p>
                <span className="q-open">{id ? '-' : '+'}</span>
            </div>
            { id ? 
                <div className="q-response" id={title}>
                    <p>{response}</p>
                </div>
            : null}

        </div>
    )
}