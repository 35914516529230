import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import instance from '../../api/instance'

import './login.scss'

export default function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [see, setSee] = useState(false); 

    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        if (localStorage.getItem('logged') !== 'true'){
            navigate("/connexion")
        }
    // eslint-disable-next-line
    }, [])

    const handleLogin = (event) => {
        event.preventDefault()

        if (email && password) {
            instance.post('/login', {
                email: email,
                password: password,
            }).then((response) => {
                if (response.status === 200)
                {
                    setError("")
                    setSuccess("Connexion effecuté, redirection...");
                    instance.defaults.headers.common['authorization'] = `Bearer ${response.data.accessToken}`;
                    localStorage.setItem('user', response.data.refreshToken)
                    localStorage.setItem('logged', true)
                    navigate("/espace-client")
                } else {
                    setError("Identifiants invalides.");
                }
            }).catch((err) => {
                setSuccess("")
                setError("Identifiants invalides.");
            })
        }
    }
    return (
        <>
            <div className="pageContent">
                <div className="connexion">
                        <NavLink to="/" className="c-back">«</NavLink>
                        <p className="c-title">NAME</p>
                        <p className="c-desc">Connexion a mon espace client</p>
                        <hr />
                        { error ? <span className="error">{error}</span> : null}
                        { success ? <span className="success">{success}</span> : null}
                        <form onSubmit={handleLogin}>
                            <div className="form-email">
                                <label className="form-text">Email</label>
                                <input type="email" placeholder="exemple@name.fr" className="form-input" onChange={(e) => setEmail(e.target.value)} value={email}></input>
                            </div>
                            <div className="form-password">
                                <label className="form-text">Mot de passe</label>
                                <input type={see ? "text" : "password"} placeholder="Votre mot de passe" className="form-input" onChange={(e) => setPassword(e.target.value)} value={password}></input>
                                <span className="form-voir" onClick={() => setSee(!see)}>Voir</span>
                            </div>
                            <button type="submit" className="form-button">Connexion</button>
                        </form>
                        <div className="c-bottom">
                            <NavLink to="/mdp-oublie">Mot de passe oublié ?</NavLink>
                            <NavLink to="/inscription">S'inscrire</NavLink>
                        </div>
                    </div>
            </div>
        </>
    )
}
