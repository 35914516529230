import React from 'react'
import Header from '../components/header/Header'
import Client from '../components/client/Client'
import Footer from '../components/footer/Footer'

export default function EspaceClient() {
    return (
        <>
            <Header />
            <Client />
            <Footer />
        </>
    )
}
