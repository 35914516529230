import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage'
import FAQPage from './pages/FAQPage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import './App.scss'
import LostPassword from './components/lostpassword/LostPassword';
import ResetPassword from './components/resetpassword/ResetPassword';
import EspaceClient from './pages/EspaceClient';
import BuyPage from './pages/BuyPage';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/faq" element={<FAQPage />} />
          <Route exact path="/espace-client/" element={<EspaceClient />} />
          <Route exact path="/connexion" element={<LoginPage />} />
          <Route exact path="/inscription" element={<RegisterPage />} />
          <Route exact path="/mdp-oublie/" element={<LostPassword />} />
          <Route exact path="/reset/:id" element={<ResetPassword />} />
          <Route exact path="/commander/:id" element={<BuyPage />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
