import React from 'react'
import Header from '../components/header/Header'
import TopBloc from '../components/topbloc/TopBloc'
import Offers from '../components/offers/Offers'
import Footer from '../components/footer/Footer'

export default function HomePage() {
    return (
        <>
            <Header />
            <TopBloc />
            <Offers />
            <Footer />
        </>
    )
}
