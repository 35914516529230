import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import './resetpassword.scss'

export default function ResetPassword() {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [see, setSee] = useState(false);
    const [seeConfirm, setSeeConfirm] = useState(false);

    const [errorPwdSecurity, setErrorPwdSecurity] = useState(null)
    const [errorPwdSame, setErrorPwdSame] = useState(null)
    const [success, setSuccess] = useState(null)

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('logged') === 'true'){
            navigate("/espace-client")
        }
    // eslint-disable-next-line
    }, [])

    const handleChange = (event) => {
        event.preventDefault()

        if (password && confirmPassword)
        {
            //Reset
            setErrorPwdSame(null)
            setErrorPwdSecurity(null)
            setSuccess(null)
            document.getElementById('confirmPassword').style.border = "2px solid rgba(0, 0, 0, 0.068)"
            document.getElementById('password').style.border = "2px solid rgba(0, 0, 0, 0.068)"

            //Verif password sont pareils
            if (password !== confirmPassword) {
                setErrorPwdSame("Les deux mots de passe ne sont pas identiques.")
                document.getElementById('confirmPassword').style.border = "2px solid rgb(151, 23, 23)"
            } else {
                //Verif password sont assez sûr
                if (password.length > 5 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[~`!#$%&*+=\-\]\\';,/{}|\\":<>@.]/g.test(password)) {
                    //Annonce de la réinitialisation
                    setSuccess("Votre mot de passe a bien été changé.")

                    //Reset input
                    setPassword(null)
                    setConfirmPassword("")
                } else {
                    setErrorPwdSecurity("Le mot de passe doit contenir au minimum 1 majuscule, 1 caractère spécial, 1 chiffre et minimum 6 caractères.")
                    document.getElementById('password').style.border = "2px solid rgb(151, 23, 23)"
                }
            }
        }
    }

    return (
        <>
            <div className="pageContent">
                <div className="resetpassword">
                    <NavLink to="/connexion" className="rp-back">«</NavLink>
                    <p className="rp-title">NAME</p>
                    <p className="rp-desc">Mot de passe oublié</p>
                    <hr />
                    <form onSubmit={handleChange}>
                        <span className="success">{ success }</span>
                        <div className="form-password">
                            <label className="form-text">Nouveau mot de passe</label>
                            <input type={see ? "text" : "password"} placeholder="Entrez votre nouveau mot de passe" className="form-input" onChange={(e) => setPassword(e.target.value)} value={password} minLength="6" id="password" required></input>
                            <span className="form-voir" onClick={() => setSee(!see)}>Voir</span>
                        </div>
                        { errorPwdSecurity ? <span className="error">{ errorPwdSecurity }</span> : null}
                        <div className="form-password">
                            <label className="form-text">Confirmer nouveau mot de passe</label>
                            <input type={seeConfirm ? "text" : "password"} placeholder="Confirmez votre nouveau mot de passe" className="form-input" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} minLength="6" id="confirmPassword" required></input>
                            <span className="form-voir" onClick={() => setSeeConfirm(!seeConfirm)}>Voir</span>
                        </div>
                        { errorPwdSame ? <span className="error">{ errorPwdSame }</span> : null}
                        <button type="submit" className="form-button">Réinitialiser</button>
                    </form>
                </div>
            </div>
        </>
    )
}
