import React from 'react'
import Header from '../components/header/Header'
import TopBloc from '../components/topbloc/TopBloc'
import FAQ from '../components/faq/Faq'
import Footer from '../components/footer/Footer'

export default function FAQPage() {
    return (
        <>
            <Header />
            <TopBloc />
            <FAQ />
            <Footer />
        </>
    )
}
