import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import instance from '../../api/instance'

import './register.scss'

export default function Register() {
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [see, setSee] = useState(false);
    const [seeConfirm, setSeeConfirm] = useState(false);

    const [errorPwdSecurity, setErrorPwdSecurity] = useState(null)
    const [errorPwdSame, setErrorPwdSame] = useState(null)
    const [error, setError] = useState("")

    const [success, setSuccess] = useState(null)

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('logged') === 'true'){
            navigate("/espace-client")
        }
    // eslint-disable-next-line
    }, [])

    const handleRegister = (event) => {
        event.preventDefault()


        if (firstname && lastname && email && password && confirmPassword)
        {
            //Reset
            setErrorPwdSame(null)
            setErrorPwdSecurity(null)
            setSuccess(null)
            document.getElementById('confirmPassword').style.border = "2px solid rgba(0, 0, 0, 0.068)"
            document.getElementById('password').style.border = "2px solid rgba(0, 0, 0, 0.068)"

            //Verif password sont pareils
            if (password !== confirmPassword) {
                setErrorPwdSame("Les deux mots de passe ne sont pas identiques.")
                document.getElementById('confirmPassword').style.border = "2px solid rgb(151, 23, 23)"
            } else {
                //Verif password sont assez sûr
                if (password.length > 5 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[~`!#$%&*+=\-\]\\';,/{}|\\":<>@.]/g.test(password)) {
                    //Inscription backend
                    instance.post('/register', {
                        firstname: capitalizeFirstLetter(firstname),
                        lastname: capitalizeFirstLetter(lastname),
                        email: email,
                        password: password,
                    }).then((response) => {
                        if (response.status === 200)
                        {
                            setError("")
                            setSuccess("Enregistrement effecuté");
                        } else {
                            setError("Une erreur est survenue.");
                        }
                    }).catch((err) => {
                        setSuccess("")
                        setError("Une erreur est survenue.");
                    })
                    //Annonce de la création du compte
                    setSuccess("Votre compte a bien été enregistré.")


                    //Reset input
                    setFirstname("")
                    setLastname("")
                    setEmail("")
                    setPassword("")
                    setConfirmPassword("")
                } else {
                    setErrorPwdSecurity("Le mot de passe doit contenir au minimum 1 majuscule, 1 caractère spécial, 1 chiffre et minimum 6 caractères.")
                    document.getElementById('password').style.border = "2px solid rgb(151, 23, 23)"
                }
            }
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <>
            <div className="pageContent">
                <div className="enregistrement">
                        <NavLink to="/connexion" className="e-back">«</NavLink>
                        <p className="e-title">NAME</p>
                        <p className="e-desc">Inscription</p>
                        <hr />
                        { success ? <span className="success">{ success }</span> : null}
                        { error ? <span className="error">{ error }</span> : null}
                        <form onSubmit={handleRegister}>
                            <div className="form-div">
                                <label className="form-text">Prénom</label>
                                <input type="text" placeholder="Votre prénom" className="form-input" onChange={(e) => setFirstname(e.target.value)} value={firstname} minLength="3" required></input>
                            </div>
                            <div className="form-div">
                                <label className="form-text">Bosquet</label>
                                <input type="text" placeholder="Votre nom" className="form-input" onChange={(e) => setLastname(e.target.value)} value={lastname} minLength="2" required></input>
                            </div>
                            <div className="form-div">
                                <label className="form-text">Email</label>
                                <input type="email" placeholder="exemple@name.fr" className="form-input" onChange={(e) => setEmail(e.target.value)} value={email} required></input>
                            </div>
                            <div className="form-password">
                                <label className="form-text">Mot de passe</label>
                                <input type={see ? "text" : "password"} placeholder="Entrez mot de passe" className="form-input" onChange={(e) => setPassword(e.target.value)} value={password} minLength="6" id="password" required></input>
                                <span className="form-voir" onClick={() => setSee(!see)}>Voir</span>
                            </div>
                            { errorPwdSecurity ? <span className="error">{ errorPwdSecurity }</span> : null}
                            <div className="form-password">
                                <label className="form-text">Confirmer mot de passe</label>
                                <input type={seeConfirm ? "text" : "password"} placeholder="Confirmez votre mot de passe" className="form-input" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} minLength="6" id="confirmPassword" required></input>
                                <span className="form-voir" onClick={() => setSeeConfirm(!seeConfirm)}>Voir</span>
                            </div>
                            { errorPwdSame ? <span className="error">{ errorPwdSame }</span> : null}
                            <button type="submit" className="form-button">Inscription</button>
                        </form>
                        <div className="e-bottom">
                            <NavLink to="/connexion">Déjà un compte ? Connexion</NavLink>
                        </div>
                    </div>
            </div>
        </>
    )
}
