import React from 'react'
import Register from './../components/register/Register'

export default function RegisterPage() {
    return (
        <>
            <Register />
        </>
    )
}
