import React from 'react'
import Login from './../components/login/Login'

export default function LoginPage() {
    return (
        <>
            <Login />
        </>
    )
}
