import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'

import './lostpassword.scss'

export default function LostPassword() {
    const navigate = useNavigate();

    const [resetSend, setResetSend] = useState(false)

    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")

    const [errorEmail, setErrorEmail] = useState(null)
    const [successSend, setSuccessSend] = useState(null)

    useEffect(() => {
        if (localStorage.getItem('logged') === 'true'){
            navigate("/espace-client")
        }
    // eslint-disable-next-line
    }, [])

    const handleLogin = (event) => {
        event.preventDefault()

        setEmail("")
        setErrorEmail(null)
        setResetSend(false)
        if (email && validateEmail(email)) {
            setResetSend(true)
            setSuccessSend("Votre code de réinitialisation vous a été envoyé par mail.")
        } else {
            setErrorEmail("Votre addresse mail n'est pas valide.")
        }
    }

    const codeValidate = (event) => {
        event.preventDefault()

        //check si le code correspond a l'adresse email
        if (code) {
            setSuccessSend("")
            navigate('/reset/5Df51cC6sd');
        }
    }

    const codeNotReceived = (event) => {
        setSuccessSend("Un nouveau code vous a été envoyé.")
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return (
        <>
            <div className="pageContent">
                <div className="lostpassword">
                        <NavLink to="/connexion" className="lp-back">«</NavLink>
                        <p className="lp-title">NAME</p>
                        <p className="lp-desc">Mot de passe oublié</p>
                        <hr />
                        { resetSend ?
                            <form onSubmit={codeValidate}>
                                <span className="success">{ successSend }</span>
                                <div className="form-email">
                                    <label className="form-text">Code de réinitialisation</label>
                                    <input type="text" placeholder="Code reçu par mail" className="form-input" onChange={(e) => setCode(e.target.value)} value={code} required></input>
                                </div>
                                { errorEmail ?
                                    <span className="error">{ errorEmail }</span>
                                : null}
                                <button type="submit" className="form-button">Envoyer</button>
                            </form>

                        :   
                            <form onSubmit={handleLogin}>
                                <div className="form-email">
                                    <label className="form-text">Email</label>
                                    <input type="email" placeholder="exemple@name.fr" className="form-input" onChange={(e) => setEmail(e.target.value)} value={email}></input>
                                </div>
                                { errorEmail ?
                                    <span className="error">{ errorEmail }</span>
                                : null}
                                <button type="submit" className="form-button">Réinitialiser</button>
                            </form> 
                        }
                        
                        <div className="lp-bottom">
                            { resetSend ? 
                                <p className="resetCode" onClick={codeNotReceived}>Code non reçu ?</p>
                            : null}
                            <NavLink to="/connexion">Connexion</NavLink>
                        </div>
                    </div>
            </div>
        </>
    )
}
