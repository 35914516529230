import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import { useParams } from 'react-router';

export default function BuyPage() {    
    let { id } = useParams();
    console.log(id)

    return (
        <>
            <Header />
            <Footer />
        </>
    )
}
