import React, { useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import loadUserInfos from '../../api/loadUserInfos'

import './header.scss'

export default function Header() {

    const [mobile, setMobile] = useState(false)
    const [logged, setLogged] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('logged') === 'true')
        {
            loadUserInfos();
            setLogged(true)
        }
    }, [])

    // Menu déroulant / Hamburger
    const toggleNav = () => {
        if(!mobile) {
            document.getElementById('header').style.height = "380px"
            document.getElementById('burger').classList.add('close')
            document.getElementById('burger').classList.remove('burger')
        } else {
            document.getElementById('header').style.height = "70px"
            document.getElementById('burger').classList.remove('close')
            document.getElementById('burger').classList.add('burger')
        }

        // Définir le mode mobile au mode inverse (true/false)
        setMobile(!mobile)
    }
    return (
        <>
            <div className="header" id='header'>
                <NavLink to="/" exact="true" className="h-name">NAME</NavLink>
                <div className="h-links" id="h-links">
                    <NavLink to="/" exact="true" className={({ isActive }) => "h-link" + (isActive ? " h-link-active" : "")}>Accueil</NavLink>
                    <NavLink to="/faq" exact="true" className={({ isActive }) => "h-link" + (isActive ? " h-link-active" : "")}>FAQ</NavLink>
                    <NavLink to="/contact" exact="true" className={({ isActive }) => "h-link" + (isActive ? " h-link-active" : "")}>Contact</NavLink>
                </div>
                <div className="h-links-account" id="h-links-account">
                    <NavLink to={logged ? "/espace-client" : "/connexion"} exact="true" className="h-link-account">Espace Client</NavLink>
                </div>
            </div>
            <div id="burger" className="burger" onClick={toggleNav}/>        
        </>
    )
}