import React from 'react'
import { NavLink } from 'react-router-dom'
import './client.scss'

export default function Clink({title, direction}) {

    return (
        <NavLink className="c-link-container" to={direction}>
            <div className="c-link">
                <p>{title}</p>
            </div>
        </NavLink>
    )
}