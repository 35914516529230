import React from 'react'
//import {NavLink} from 'react-router-dom'

import './footer.scss'

export default function Footer() {
    return (
        <div className="footer">

        </div>
    )
}