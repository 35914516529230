import React, { useState } from 'react'
import { useNavigate } from 'react-router';

import './offers.scss'

export default function Offers(props) {
    const navigate = useNavigate();

    const [buy, setBuy] = useState(false)

    function toggleBuy()
    {
        setBuy(!buy)
    }

    function handleBuy()
    {
        navigate('/commander/' + props.title)
    }
    return (
        <div className="offer">
            <div className="o-product">
                <p className="o-product-name">{props.title}</p>
            </div>
            <div className="o-product-details">
                <div className="o-product-detail">
                    <p className="o-product-detail-p">Nombres d'images</p>
                    <p className="o-product-detail-p o-color">{props.images}</p>
                </div>
                <div className="o-product-detail">
                    <p className="o-product-detail-p">Taille d'image max</p>
                    <p className="o-product-detail-p o-color">{props.size_max} Mo</p>
                </div>
                <div className="o-product-detail">
                    <p className="o-product-detail-p">Téléchargements</p>
                    <p className="o-product-detail-p o-color">{props.download}</p>
                </div>
            </div>
            <p className="o-product-buy" onClick={toggleBuy}>Commander {props.price} €</p>
            { buy ? 
                <div className="o-confirm-buttons">
                    <p>Confirmer l'achat ?</p>
                    <div>
                        <button className="o-confirm" onClick={handleBuy}>Oui</button>
                        <button className="o-refused" onClick={toggleBuy}>Non</button>
                    </div>
                </div> 
            : null}
        </div>
    )
}
