import React from 'react'
import Questions from './Questions'
import './faq.scss'

export default function FAQ() {
    return (
        <div className="faq">
            <h1>Foire aux questions</h1>
            <div className="questions">
                <Questions />
            </div>
        </div>
    )
}