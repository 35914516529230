import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import instance from '../../api/instance';
import Loader from '../loader/Loader';
import Clink from './C-link';
import './client.scss'

export default function Client() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (localStorage.getItem('logged') !== 'true'){
            navigate("/connexion")
        }
        const request = async () => {
            const response = await instance.get('/profil');
            if (response){
                setData(response.data)
                setLoading(false)
            }
        } 
        request()
    // eslint-disable-next-line
    }, [])

    function logout() {
        localStorage.setItem('logged', false)
        localStorage.removeItem('user')
        instance.defaults.headers.common['authorization'] = `Bearer`
        navigate("/")
    }
    return (
        <> 
            <div className="client">
                { loading ? <div className="c-direction"><Loader /></div> :
                <>
                    <div className="c-top">
                        <div className="client-infos">
                            <h2>{data.firstname} {data.lastname}</h2>
                            <div className="client-infos-details">
                                <span className={data.grade === 'Admin' ? "span-bg red" : "span-bg"}>{data.grade}</span>
                                <span className="span-bg">{data.money} €</span>
                            </div>
                            <span className="span-bg top-margin">Offre actuelle: {data.offre}</span>    
                        </div>
                        <div className="c-pages">
                            <span className="c-logout" onClick={logout}>Déconnexion</span>
                        </div>
                    </div>
                    <div className="c-direction">
                        <div className="c-choices">
                            <Clink title="Profil" direction="/accueil"/>
                            <Clink title="Se crediter" direction="/accueil"/>
                            <Clink title="Modifier mot de passe" direction="/accueil"/>
                            <Clink title="Bientot" direction="/accueil"/>
                            <Clink title="Bientot" direction="/accueil"/>
                            <Clink title="Bientot" direction="/accueil"/>

                        </div>

                    </div>
                </>
                }
            </div>
        </>
    )
}