import React from 'react'
//import {NavLink} from 'react-router-dom'

import './loader.scss'

export default function Loader() {
    return (
        <div id="wrapper">
            <div className="profile-main-loader">
            <div className="loader">
                <svg className="circular-loader"viewBox="25 25 50 50" >
                <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#0099ff" strokeWidth="2" />
                </svg>
            </div>
            </div>
        </div>
    )
}