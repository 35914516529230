import React from 'react'
import { Link } from 'react-router-dom';

import './topbloc.scss'

export default function TopBloc() {

    const executeScroll = () => {
        setTimeout(function() {
            document.getElementById('offres').scrollIntoView()
        }, 200);
    }

    return (
        <div className="top-bloc">
            <div className="tb-details">
                <p className="tb-title">NAME</p>
                <p className="tb-subtitle">VOTRE HEBERGEUR D'IMAGES</p>
                <p className="tb-desc">Besoin d'un hébergement pour vos images ? Vous êtes au bon endroit !</p>
                <Link to="/" onClick={executeScroll} className="tb-button" smooth="true">» Voir nos offres</Link>
            </div>
            <img src="banner_image.png" alt="shared-hosting"/>
        </div>
    )
}