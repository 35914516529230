import React, { useEffect, useState } from 'react'
import Question from './Question'
import './faq.scss'
import axios from 'axios';
import Loader from '../loader/Loader';

export default function Questions() {
    const [questions, setQuestions] = useState([])
    
    useEffect(() => {
        renderFAQ()
    }, [])

    const renderFAQ = async () => {
        try {
            let res = await axios.get('https://hosting-images.herokuapp.com/api/faq');
            //let res = await axios.get('http://localhost:5000/api/faq');
            let faq = res.data;

            setQuestions(
                faq.map((question, _id) => (
                    <Question key={_id} title={question.question} response={question.response} />
                ))
            )
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {Object.keys(questions).length > 0 ? questions : <Loader />}
        </>
    )
}