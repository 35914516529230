import React from 'react'
import Offer from './Offer'
import './offers.scss'

export default function Offers() {
    return (
        <div className="offers" id="offres">
            <div className="o-top">
                <p className="o-title">Découvrez nos offres</p>
            </div>
            <div className="o-list">
                <Offer index="1" title="Starter" images="10" size_max="5" download="Illimité" price="0.00"/>
                <Offer index="2" title="Medium" images="200" size_max="20" download="Illimité" price="2.99"/>
                <Offer index="3" title="Large" images="Illimité" size_max="35" download="Illimité" price="5.99"/>
            </div>
        </div>
    )
}
